// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Session$Weblab from "../service/Session.bs.js";
import * as Core from "@material-ui/core";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as SupabaseClient$Weblab from "../bindings/supabase/SupabaseClient.bs.js";
import * as Styles from "@material-ui/core/styles";
import * as GatsbyThemeMaterialUi from "gatsby-theme-material-ui";
import GitHub from "@material-ui/icons/GitHub";
import DiscordSvg from "/src/assets/svg/discord.svg";
import ExitToApp from "@material-ui/icons/ExitToApp";
import AccountCircle from "@material-ui/icons/AccountCircle";

var transOri = {
  horizontal: 0,
  vertical: -40
};

function Topbar(Props) {
  var setLoginDialog = Props.setLoginDialog;
  var session = React.useContext(Session$Weblab.SessionContext.context);
  var match = React.useState(function () {
        
      });
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var handleClick = function ($$event) {
    var target = $$event.currentTarget;
    return Curry._1(setAnchorEl, (function (_x) {
                  return Caml_option.some(target);
                }));
  };
  var handleClose = function (_event, _reason) {
    return Curry._1(setAnchorEl, (function (_x) {
                  
                }));
  };
  var theme = Styles.useTheme();
  return React.createElement(Core.Toolbar, {
              children: null,
              variant: "dense",
              style: {
                background: "linear-gradient(135deg, #273377, #3949ab)",
                maxHeight: "56px",
                width: "100%"
              }
            }, React.createElement(Core.Box, {
                  style: {
                    color: "white"
                  },
                  children: React.createElement(Core.Typography, {
                        children: React.createElement(Core.Box, {
                              component: "span",
                              style: {
                                color: theme.palette.secondary.main
                              },
                              children: "Dashbook"
                            }),
                        variant: "h4",
                        style: {
                          fontWeight: "700",
                          margin: "8px"
                        }
                      }),
                  flexGrow: 1,
                  ml: 4,
                  pr: 2
                }), React.createElement(Core.Box, {
                  children: null,
                  alignItems: "center",
                  display: {
                    xs: "none",
                    sm: "flex",
                    md: "flex"
                  }
                }, React.createElement(Core.Box, {
                      children: React.createElement(Core.Typography, {
                            children: React.createElement(GatsbyThemeMaterialUi.Link, {
                                  to: "/hub",
                                  children: "NotebookHub",
                                  style: {
                                    color: "white"
                                  }
                                }),
                            variant: "body1"
                          }),
                      mr: 4
                    }), React.createElement(Core.Box, {
                      children: React.createElement(Core.Typography, {
                            children: React.createElement(GatsbyThemeMaterialUi.Link, {
                                  to: "/documentation",
                                  children: "Documentation",
                                  style: {
                                    color: "white"
                                  }
                                }),
                            variant: "body1"
                          }),
                      mr: 4
                    }), React.createElement(Core.Box, {
                      children: React.createElement(Core.IconButton, {
                            children: React.createElement(GatsbyThemeMaterialUi.Link, {
                                  to: "https://github.com/dashbook",
                                  children: React.createElement(GitHub, {}),
                                  style: {
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center"
                                  }
                                })
                          })
                    }), React.createElement(Core.Box, {
                      clone: true,
                      children: React.createElement(Core.IconButton, {
                            children: React.createElement(GatsbyThemeMaterialUi.Link, {
                                  to: "https://discord.gg/PPxHY7pKnD",
                                  children: React.createElement(DiscordSvg, {
                                        className: "MuiSvgIcon-root"
                                      }),
                                  style: {
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center"
                                  }
                                })
                          })
                    })), React.createElement(Core.Box, {
                  children: session !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Core.Tooltip, {
                              children: React.createElement(Core.IconButton, {
                                    onClick: handleClick,
                                    style: {
                                      color: "white",
                                      maxHeight: "56"
                                    },
                                    children: React.createElement(AccountCircle, {
                                          fontSize: "large"
                                        })
                                  }),
                              title: "Account"
                            }), React.createElement(Core.Menu, {
                              keepMounted: true,
                              transformOrigin: transOri,
                              anchorEl: anchorEl,
                              children: React.createElement(Core.MenuItem, {
                                    children: null,
                                    dense: true,
                                    onClick: (function (evt) {
                                        Curry._1(setAnchorEl, (function (_x) {
                                                
                                              }));
                                        SupabaseClient$Weblab.supabase.auth.signOut().then(function (param) {
                                                return Promise.resolve(undefined);
                                              }).catch(function (error) {
                                              var exn = Js_exn.anyToExnInternal(error);
                                              alert(Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(exn), (function (prim) {
                                                              return prim.message;
                                                            })), "Logout failed."));
                                              return Promise.reject(exn);
                                            });
                                        
                                      })
                                  }, React.createElement(Core.ListItemIcon, {
                                        children: React.createElement(ExitToApp, {})
                                      }), React.createElement(Core.ListItemText, {
                                        children: "Logout"
                                      })),
                              MenuListProps: {
                                dense: true,
                                disablePadding: true
                              },
                              onClose: handleClose,
                              open: Belt_Option.isSome(anchorEl),
                              transitionDuration: 0.2,
                              variant: "menu"
                            })) : React.createElement(Core.Button, {
                          onClick: (function (param) {
                              return Curry._1(setLoginDialog, (function (param) {
                                            return true;
                                          }));
                            }),
                          children: "Log in",
                          color: "secondary",
                          variant: "contained"
                        }),
                  alignItems: "center",
                  display: "flex",
                  marginLeft: 2,
                  maxHeight: 56,
                  width: 80
                }));
}

var make = Topbar;

export {
  transOri ,
  make ,
  
}
/* react Not a pure module */
