// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";
import Weblab_logoSvg from "/src/assets/svg/weblab_logo.svg";

var useStyles = Styles.makeStyles(function (_theme) {
      return {
              svg: {
                maxHeight: "40px",
                maxWidth: "40px",
                verticalAlign: "middle"
              },
              tabButton: {
                minWidth: "40px"
              }
            };
    });

var Styles$1 = {
  useStyles: useStyles
};

function Sidebar(Props) {
  var toggle_sidebar = Props.toggle_sidebar;
  var activeTab = Props.activeTab;
  var toggle_active_tab = Props.toggle_active_tab;
  var children = Props.children;
  var classes = Curry._1(useStyles, undefined);
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Box, {
                  style: {
                    height: "56px",
                    lineHeight: "56px",
                    textAlign: "center",
                    width: "48px"
                  },
                  children: React.createElement(Core.Link, {
                        children: React.createElement(Weblab_logoSvg, {
                              color: "secondary",
                              className: classes.svg,
                              fontSize: "large"
                            }),
                        href: "/"
                      })
                }), React.createElement(Core.Tabs, {
                  children: React.Children.map(children, (function (elem, i) {
                          return React.createElement(Core.Tab, {
                                      className: classes.tabButton,
                                      label: elem,
                                      onClick: (function (_event) {
                                          Curry._1(toggle_active_tab, String(i));
                                          Curry._1(toggle_sidebar, true);
                                          
                                        }),
                                      value: String(i)
                                    });
                        })),
                  orientation: "vertical",
                  textColor: "primary",
                  value: activeTab
                }));
}

var make = Sidebar;

export {
  Styles$1 as Styles,
  make ,
  
}
/* useStyles Not a pure module */
