// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

var context = React.createContext(undefined);

var provider = context.Provider;

function Session$SessionContext$Provider(Props) {
  var value = Props.value;
  var children = Props.children;
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var Provider = {
  provider: provider,
  make: Session$SessionContext$Provider
};

var SessionContext = {
  context: context,
  Provider: Provider
};

function userReducer(_state, userAction) {
  if (userAction) {
    return userAction._0;
  }
  
}

export {
  SessionContext ,
  userReducer ,
  
}
/* context Not a pure module */
