// Generated by ReScript, PLEASE EDIT WITH CARE


function toggle_sidebar(toggle) {
  if (toggle) {
    var divSidebar = document.querySelector("#wrapper");
    if (!(divSidebar == null)) {
      divSidebar.style.gridTemplateColumns = "48px auto 1fr";
      return ;
    } else {
      return ;
    }
  }
  var divSidebar$1 = document.querySelector("#wrapper");
  if (!(divSidebar$1 == null)) {
    divSidebar$1.style.gridTemplateColumns = "48px 0px 1fr";
    return ;
  }
  
}

export {
  toggle_sidebar ,
  
}
/* No side effect */
