// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Lab from "@material-ui/lab";
import * as LoginBase$Weblab from "./LoginBase.bs.js";
import * as Core from "@material-ui/core";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as SupabaseClient$Weblab from "../bindings/supabase/SupabaseClient.bs.js";
import * as GatsbyThemeMaterialUi from "gatsby-theme-material-ui";
import GitHub from "@material-ui/icons/GitHub";
import GoogleSvg from "/src/assets/svg/google.svg";

function Login(Props) {
  var loginDialog = Props.loginDialog;
  var setLoginDialog = Props.setLoginDialog;
  var match = React.useReducer(LoginBase$Weblab.loginReducer, {
        email: "",
        password: "",
        alert: undefined
      });
  var loginDispatch = match[1];
  var login = match[0];
  var match$1 = React.useReducer(LoginBase$Weblab.registrationReducer, {
        email: "",
        password: "",
        secondPassword: "",
        alert: undefined,
        success: undefined
      });
  var registrationDispatch = match$1[1];
  var registration = match$1[0];
  var match$2 = React.useReducer(LoginBase$Weblab.resetReducer, {
        email: "",
        alert: undefined,
        success: undefined
      });
  var resetDispatch = match$2[1];
  var reset = match$2[0];
  var match$3 = React.useState(function () {
        return "0";
      });
  var setTab = match$3[1];
  var tab = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setLoading = match$4[1];
  var loading = match$4[0];
  var submitLogin = function (evt) {
    evt.preventDefault();
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    SupabaseClient$Weblab.supabase.auth.signIn({
              email: login.email,
              password: login.password,
              provider: undefined
            }).then(function (response) {
            var error = response.error;
            if (error == null) {
              Curry._1(setLoading, (function (param) {
                      return false;
                    }));
              Curry._1(setLoginDialog, (function (param) {
                      return false;
                    }));
              Curry._1(loginDispatch, /* Clear */0);
              return Promise.resolve(undefined);
            } else {
              return Promise.reject(Js_exn.raiseError(error.message));
            }
          }).catch(function (error) {
          Curry._1(setLoading, (function (param) {
                  return false;
                }));
          Curry._1(loginDispatch, {
                TAG: /* SetAlert */2,
                _0: Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(Js_exn.anyToExnInternal(error)), (function (prim) {
                            return prim.message;
                          })), "Log in failed.")
              });
          return Promise.resolve(undefined);
        });
    
  };
  var submitRegistration = function (evt) {
    evt.preventDefault();
    if (LoginBase$Weblab.samePassword(registration.password, registration.secondPassword)) {
      if (LoginBase$Weblab.strongPassword(registration.password)) {
        Curry._1(setLoading, (function (param) {
                return true;
              }));
        SupabaseClient$Weblab.supabase.auth.signUp({
                  email: registration.email,
                  password: registration.password
                }).then(function (response) {
                var error = response.error;
                if (error == null) {
                  Curry._1(setLoading, (function (param) {
                          return false;
                        }));
                  Curry._1(registrationDispatch, {
                        TAG: /* Success */4,
                        _0: "A verification email has been sent to your address."
                      });
                  return Promise.resolve(undefined);
                } else {
                  return Promise.reject(Js_exn.raiseError(error.message));
                }
              }).catch(function (error) {
              Curry._1(setLoading, (function (param) {
                      return false;
                    }));
              Curry._1(registrationDispatch, {
                    TAG: /* SetAlert */3,
                    _0: Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(Js_exn.anyToExnInternal(error)), (function (prim) {
                                return prim.message;
                              })), "Check your email for the login link!")
                  });
              return Promise.resolve(undefined);
            });
        return ;
      } else {
        return Curry._1(registrationDispatch, {
                    TAG: /* SetAlert */3,
                    _0: "Password must be at least 8 characters long and must contain at least one uppercase letter, one lowercase letter, one digit and one special character."
                  });
      }
    } else {
      return Curry._1(registrationDispatch, {
                  TAG: /* SetAlert */3,
                  _0: "Both passwords must be the same."
                });
    }
  };
  var submitReset = function (evt) {
    evt.preventDefault();
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    SupabaseClient$Weblab.supabase.auth.api.resetPasswordForEmail(reset.email).then(function (response) {
            var error = response.error;
            if (error == null) {
              Curry._1(setLoading, (function (param) {
                      return false;
                    }));
              Curry._1(resetDispatch, {
                    TAG: /* Success */2,
                    _0: "A password reset email has been sent to your email address."
                  });
              return Promise.resolve(undefined);
            } else {
              return Promise.reject(Js_exn.raiseError(error.message));
            }
          }).catch(function (error) {
          Curry._1(setLoading, (function (param) {
                  return false;
                }));
          Curry._1(resetDispatch, {
                TAG: /* SetAlert */1,
                _0: Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(Js_exn.anyToExnInternal(error)), (function (prim) {
                            return prim.message;
                          })), "Please verify that the Email is correct.")
              });
          return Promise.resolve(undefined);
        });
    
  };
  var signInOAuth = function (provider, evt) {
    evt.preventDefault();
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    SupabaseClient$Weblab.supabase.auth.signIn({
              email: undefined,
              password: undefined,
              provider: provider
            }).then(function (response) {
            var error = response.error;
            if (error == null) {
              Curry._1(setLoading, (function (param) {
                      return false;
                    }));
              Curry._1(setLoginDialog, (function (param) {
                      return false;
                    }));
              Curry._1(loginDispatch, /* Clear */0);
              return Promise.resolve(undefined);
            } else {
              return Promise.reject(Js_exn.raiseError(error.message));
            }
          }).catch(function (error) {
          Curry._1(setLoading, (function (param) {
                  return false;
                }));
          Curry._1(loginDispatch, {
                TAG: /* SetAlert */2,
                _0: Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(Js_exn.anyToExnInternal(error)), (function (prim) {
                            return prim.message;
                          })), "Log in failed.")
              });
          return Promise.resolve(undefined);
        });
    
  };
  var str = login.alert;
  var str$1 = registration.alert;
  var str$2 = registration.success;
  var str$3 = reset.alert;
  var str$4 = reset.success;
  return React.createElement(Core.Dialog, {
              children: null,
              onClose: (function (_evt, _reason) {
                  return Curry._1(setLoginDialog, (function (param) {
                                return false;
                              }));
                }),
              open: loginDialog
            }, React.createElement(Core.Tabs, {
                  children: null,
                  indicatorColor: "secondary",
                  textColor: "primary",
                  value: tab,
                  variant: "fullWidth"
                }, React.createElement(Core.Tab, {
                      label: "Log in",
                      onClick: (function (param) {
                          return Curry._1(setTab, (function (param) {
                                        return "0";
                                      }));
                        }),
                      value: "0"
                    }), React.createElement(Core.Tab, {
                      label: "Register",
                      onClick: (function (param) {
                          return Curry._1(setTab, (function (param) {
                                        return "1";
                                      }));
                        }),
                      value: "1"
                    })), React.createElement(Lab.TabContext, {
                  children: null,
                  value: tab
                }, React.createElement(Lab.TabPanel, {
                      children: React.createElement(Core.DialogContent, {
                            children: null
                          }, React.createElement(Core.Box, {
                                clone: true,
                                children: React.createElement("form", {
                                      onSubmit: submitLogin
                                    }, React.createElement(Core.FormGroup, {
                                          children: React.createElement(Core.FormControlLabel, {
                                                control: React.createElement(Core.TextField, {
                                                      autoFocus: true,
                                                      margin: "dense",
                                                      type: "email",
                                                      variant: "outlined"
                                                    }),
                                                label: React.createElement(Core.FormLabel, {
                                                      children: "Email"
                                                    }),
                                                labelPlacement: "top",
                                                onChange: (function (evt) {
                                                    return Curry._1(loginDispatch, {
                                                                TAG: /* ChangeEmail */0,
                                                                _0: evt.currentTarget.value
                                                              });
                                                  }),
                                                value: login.email
                                              })
                                        }), React.createElement(Core.FormGroup, {
                                          children: React.createElement(Core.FormControlLabel, {
                                                control: React.createElement(Core.TextField, {
                                                      margin: "dense",
                                                      type: "password",
                                                      variant: "outlined"
                                                    }),
                                                label: React.createElement(Core.FormLabel, {
                                                      children: "Password"
                                                    }),
                                                labelPlacement: "top",
                                                onChange: (function (evt) {
                                                    return Curry._1(loginDispatch, {
                                                                TAG: /* ChangePassword */1,
                                                                _0: evt.currentTarget.value
                                                              });
                                                  }),
                                                value: login.password
                                              })
                                        }), str !== undefined ? React.createElement(Lab.Alert, {
                                            children: str,
                                            severity: "error"
                                          }) : null, React.createElement(Core.Button, {
                                          style: {
                                            margin: "12px"
                                          },
                                          children: "Log in",
                                          color: "primary",
                                          disabled: loading,
                                          type: "submit",
                                          variant: "contained"
                                        }), React.createElement(Core.Typography, {
                                          children: null
                                        }, "Forgotten your passord? ", React.createElement(Core.Link, {
                                              children: "Reset your Password",
                                              onClick: (function (param) {
                                                  return Curry._1(setTab, (function (param) {
                                                                return "2";
                                                              }));
                                                }),
                                              href: "#",
                                              rel: "noopener"
                                            }), ".")),
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                gridGap: 24
                              }), React.createElement(Core.Divider, {
                                variant: "fullWidth",
                                style: {
                                  marginTop: "32px",
                                  marginBottom: "32px"
                                }
                              }), React.createElement(Core.Box, {
                                children: null,
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                gridGap: 16
                              }, React.createElement(Core.Button, {
                                    onClick: (function (param) {
                                        return signInOAuth("github", param);
                                      }),
                                    children: "Sign in with Github",
                                    disabled: loading,
                                    startIcon: React.createElement(GitHub, {}),
                                    variant: "contained"
                                  }), React.createElement(Core.Button, {
                                    onClick: (function (param) {
                                        return signInOAuth("google", param);
                                      }),
                                    children: "Sign in with Google",
                                    disabled: loading,
                                    startIcon: React.createElement(GoogleSvg, {
                                          className: "MuiSvgIcon-root"
                                        }),
                                    variant: "contained"
                                  }))),
                      value: "0"
                    }), React.createElement(Lab.TabPanel, {
                      children: React.createElement(Core.DialogContent, {
                            children: React.createElement(Core.Box, {
                                  clone: true,
                                  children: React.createElement("form", {
                                        onSubmit: submitRegistration
                                      }, React.createElement(Core.FormGroup, {
                                            children: React.createElement(Core.FormControlLabel, {
                                                  control: React.createElement(Core.TextField, {
                                                        autoFocus: true,
                                                        margin: "dense",
                                                        type: "email",
                                                        variant: "outlined"
                                                      }),
                                                  label: React.createElement(Core.FormLabel, {
                                                        children: "Email"
                                                      }),
                                                  labelPlacement: "top",
                                                  onChange: (function (evt) {
                                                      return Curry._1(registrationDispatch, {
                                                                  TAG: /* ChangeEmail */0,
                                                                  _0: evt.currentTarget.value
                                                                });
                                                    }),
                                                  value: registration.email
                                                })
                                          }), React.createElement(Core.FormGroup, {
                                            children: React.createElement(Core.FormControlLabel, {
                                                  control: React.createElement(Core.TextField, {
                                                        margin: "dense",
                                                        type: "password",
                                                        variant: "outlined"
                                                      }),
                                                  label: React.createElement(Core.FormLabel, {
                                                        children: "Password"
                                                      }),
                                                  labelPlacement: "top",
                                                  onChange: (function (evt) {
                                                      return Curry._1(registrationDispatch, {
                                                                  TAG: /* ChangePassword */1,
                                                                  _0: evt.currentTarget.value
                                                                });
                                                    }),
                                                  value: registration.password
                                                })
                                          }), React.createElement(Core.FormGroup, {
                                            children: React.createElement(Core.FormControlLabel, {
                                                  control: React.createElement(Core.TextField, {
                                                        margin: "dense",
                                                        type: "password",
                                                        variant: "outlined"
                                                      }),
                                                  label: React.createElement(Core.FormLabel, {
                                                        children: "Repeat Password"
                                                      }),
                                                  labelPlacement: "top",
                                                  onChange: (function (evt) {
                                                      return Curry._1(registrationDispatch, {
                                                                  TAG: /* ChangeSecondPassword */2,
                                                                  _0: evt.currentTarget.value
                                                                });
                                                    }),
                                                  value: registration.secondPassword
                                                })
                                          }), str$1 !== undefined ? React.createElement(Lab.Alert, {
                                              children: str$1,
                                              severity: "error"
                                            }) : null, React.createElement(Core.Typography, {
                                            children: null,
                                            display: "initial"
                                          }, "By signing up you agree to our ", React.createElement(GatsbyThemeMaterialUi.Link, {
                                                to: "/privacy-policy",
                                                children: "privacy policy"
                                              }), "."), React.createElement(Core.Button, {
                                            style: {
                                              margin: "12px"
                                            },
                                            children: "Sign up",
                                            color: "primary",
                                            disabled: loading,
                                            type: "submit",
                                            variant: "contained"
                                          }), str$2 !== undefined ? React.createElement(Lab.Alert, {
                                              children: str$2,
                                              color: "success"
                                            }) : null),
                                  alignItems: "center",
                                  display: "flex",
                                  flexDirection: "column",
                                  gridGap: 24
                                })
                          }),
                      value: "1"
                    }), React.createElement(Lab.TabPanel, {
                      children: React.createElement(Core.DialogContent, {
                            children: React.createElement(Core.Box, {
                                  clone: true,
                                  children: React.createElement("form", {
                                        onSubmit: submitReset
                                      }, React.createElement(Core.FormGroup, {
                                            children: React.createElement(Core.FormControlLabel, {
                                                  control: React.createElement(Core.TextField, {
                                                        autoFocus: true,
                                                        margin: "dense",
                                                        type: "email",
                                                        variant: "outlined"
                                                      }),
                                                  label: React.createElement(Core.FormLabel, {
                                                        children: "Email"
                                                      }),
                                                  labelPlacement: "top",
                                                  onChange: (function (evt) {
                                                      return Curry._1(resetDispatch, {
                                                                  TAG: /* ChangeEmail */0,
                                                                  _0: evt.currentTarget.value
                                                                });
                                                    }),
                                                  value: reset.email
                                                })
                                          }), str$3 !== undefined ? React.createElement(Lab.Alert, {
                                              children: str$3,
                                              severity: "error"
                                            }) : null, React.createElement(Core.Button, {
                                            style: {
                                              margin: "12px"
                                            },
                                            children: "Reset Password",
                                            color: "primary",
                                            disabled: loading,
                                            type: "submit",
                                            variant: "contained"
                                          }), str$4 !== undefined ? React.createElement(Lab.Alert, {
                                              children: str$4,
                                              severity: "success"
                                            }) : null),
                                  alignItems: "center",
                                  display: "flex",
                                  flexDirection: "column",
                                  gridGap: 24
                                })
                          }),
                      value: "2"
                    })));
}

var supabase = SupabaseClient$Weblab.supabase;

var make = Login;

export {
  supabase ,
  make ,
  
}
/* react Not a pure module */
