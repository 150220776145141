// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Theme$Weblab from "../../service/Theme.bs.js";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";

function Sidepane_Header(Props) {
  var toggle_sidebar = Props.toggle_sidebar;
  var children = Props.children;
  var theme = Styles.useTheme();
  var darkMode = Theme$Weblab.getMode(theme);
  return React.createElement(Core.Toolbar, {
              children: null,
              variant: "dense"
            }, React.createElement(Core.Box, {
                  children: React.createElement(Core.Typography, {
                        children: children,
                        color: darkMode ? "textPrimary" : "primary",
                        variant: "h6"
                      }),
                  flexGrow: 1
                }), React.createElement(Core.Tooltip, {
                  children: React.createElement(Core.Button, {
                        onClick: (function (_event) {
                            return Curry._1(toggle_sidebar, false);
                          }),
                        children: React.createElement(Close, {
                              fontSize: "small"
                            }),
                        color: darkMode ? "default" : "primary",
                        size: "small"
                      }),
                  title: "Close sidepane"
                }));
}

var make = Sidepane_Header;

export {
  make ,
  
}
/* react Not a pure module */
