// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

var NotTheRightFileExtension = /* @__PURE__ */Caml_exceptions.create("Errors-Weblab.NotTheRightFileExtension");

var NoRegularJSONNotebook = /* @__PURE__ */Caml_exceptions.create("Errors-Weblab.NoRegularJSONNotebook");

var FileNotFound = /* @__PURE__ */Caml_exceptions.create("Errors-Weblab.FileNotFound");

var FileTypeNotSupported = /* @__PURE__ */Caml_exceptions.create("Errors-Weblab.FileTypeNotSupported");

var UnsupportedOutputType = /* @__PURE__ */Caml_exceptions.create("Errors-Weblab.UnsupportedOutputType");

var UnsupportedCellType = /* @__PURE__ */Caml_exceptions.create("Errors-Weblab.UnsupportedCellType");

var HashmapToArrayConversionFailed = /* @__PURE__ */Caml_exceptions.create("Errors-Weblab.HashmapToArrayConversionFailed");

var InsertNotebookSupabaseFailed = /* @__PURE__ */Caml_exceptions.create("Errors-Weblab.InsertNotebookSupabaseFailed");

var NoWeblabError = /* @__PURE__ */Caml_exceptions.create("Errors-Weblab.NoWeblabError");

var Message = /* @__PURE__ */Caml_exceptions.create("Errors-Weblab.Message");

var MultipleErrors = /* @__PURE__ */Caml_exceptions.create("Errors-Weblab.MultipleErrors");

function getErrorMessage(err) {
  if (err.RE_EXN_ID === NotTheRightFileExtension) {
    return "File has not the right extension.";
  } else if (err.RE_EXN_ID === NoRegularJSONNotebook) {
    return "Could not parse the JSON in the provided notebook.";
  } else if (err.RE_EXN_ID === FileNotFound) {
    return "The requested File cannot be found.";
  } else if (err.RE_EXN_ID === FileTypeNotSupported) {
    return "The file ttpe is not supported.";
  } else if (err.RE_EXN_ID === UnsupportedOutputType) {
    return "The output type is not supported.";
  } else if (err.RE_EXN_ID === UnsupportedCellType) {
    return "The cell type is not supported.";
  } else if (err.RE_EXN_ID === HashmapToArrayConversionFailed) {
    return "Conversion from Hashmap to Array failed.";
  } else if (err.RE_EXN_ID === InsertNotebookSupabaseFailed) {
    return "Coulnd't insert the notebook into the database.";
  } else if (err.RE_EXN_ID === NoWeblabError) {
    return "The Exception doesn't contain a Weblab Error.";
  } else if (err.RE_EXN_ID === Message) {
    return err._1;
  } else if (err.RE_EXN_ID === MultipleErrors) {
    return Belt_List.reduce(err._1, "", (function (str, error) {
                  return str + " " + getErrorMessage(error);
                }));
  } else {
    return "Internal Weblab error.";
  }
}

function alertError(res) {
  if (res.TAG === /* Ok */0) {
    return ;
  }
  alert(getErrorMessage(res._0));
  
}

var WeblabError = /* @__PURE__ */Caml_exceptions.create("Errors-Weblab.WeblabError");

function toExn(err) {
  throw {
        RE_EXN_ID: WeblabError,
        _1: err,
        Error: new Error()
      };
}

function fromExn(exn) {
  if (exn.RE_EXN_ID === WeblabError) {
    return exn._1;
  }
  var e = Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(exn), (function (prim) {
          return prim.message;
        }));
  if (e !== undefined) {
    return {
            RE_EXN_ID: Message,
            _1: e
          };
  } else {
    return {
            RE_EXN_ID: NoWeblabError
          };
  }
}

function fromPromiseError(error) {
  return fromExn(Js_exn.anyToExnInternal(error));
}

export {
  NotTheRightFileExtension ,
  NoRegularJSONNotebook ,
  FileNotFound ,
  FileTypeNotSupported ,
  UnsupportedOutputType ,
  UnsupportedCellType ,
  HashmapToArrayConversionFailed ,
  InsertNotebookSupabaseFailed ,
  NoWeblabError ,
  Message ,
  MultipleErrors ,
  getErrorMessage ,
  alertError ,
  WeblabError ,
  toExn ,
  fromExn ,
  fromPromiseError ,
  
}
/* No side effect */
