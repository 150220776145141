// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Lab from "@material-ui/lab";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";

var useStyles = Styles.makeStyles(function (_theme) {
      return {
              closeButton: {
                float: "right"
              },
              tabHeading: {
                display: "inline"
              },
              sideboard2Tab: {
                display: "flex",
                maxHeight: "100vh",
                padding: "0px",
                flexDirection: "column"
              }
            };
    });

var Styles$1 = {
  useStyles: useStyles
};

function Sidepane(Props) {
  var activeTab = Props.activeTab;
  var children = Props.children;
  var classes = Curry._1(useStyles, undefined);
  return React.createElement(Core.Box, {
              style: {
                height: "100vh",
                maxWidth: "256px",
                minWidth: "256px"
              },
              children: React.createElement(Lab.TabContext, {
                    children: React.Children.map(children, (function (elem, i) {
                            return React.createElement(Lab.TabPanel, {
                                        children: elem,
                                        className: classes.sideboard2Tab,
                                        value: String(i)
                                      });
                          })),
                    value: activeTab
                  })
            });
}

var make = Sidepane;

export {
  Styles$1 as Styles,
  make ,
  
}
/* useStyles Not a pure module */
