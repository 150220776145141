// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Core from "@material-ui/core";
import * as Sidepane_Header$Weblab from "./Sidepane_Header.bs.js";

function Sidepane_Settings(Props) {
  var toggle_sidebar = Props.toggle_sidebar;
  var darkMode = Props.darkMode;
  var setDarkMode = Props.setDarkMode;
  return React.createElement(React.Fragment, undefined, React.createElement(Sidepane_Header$Weblab.make, {
                  toggle_sidebar: toggle_sidebar,
                  children: "Settings"
                }), React.createElement(Core.Divider, {}), React.createElement(Core.List, {
                  children: React.createElement(Core.ListItem, {
                        children: React.createElement(Core.ListItemText, {
                              children: React.createElement(Core.FormGroup, {
                                    children: React.createElement(Core.FormControlLabel, {
                                          control: React.createElement(Core.Switch, {
                                                checked: darkMode,
                                                onChange: (function (param) {
                                                    window.localStorage.setItem("darkMode", Pervasives.string_of_bool(!darkMode));
                                                    return Curry._1(setDarkMode, (function (param) {
                                                                  return !darkMode;
                                                                }));
                                                  }),
                                                size: "small"
                                              }),
                                          label: React.createElement(Core.Typography, {
                                                children: "Dark mode",
                                                variant: "body2",
                                                style: {
                                                  paddingLeft: "8px"
                                                }
                                              })
                                        })
                                  })
                            }),
                        dense: true
                      }),
                  dense: true,
                  style: {
                    paddingLeft: "16px"
                  }
                }));
}

var make = Sidepane_Settings;

export {
  make ,
  
}
/* react Not a pure module */
